// eslint-disable-next-line no-unused-vars
const env = process.env;

export const config = ({
  // Have to set default values for repo-generator
  scroll = () => {},
  IOS17Filled = () => {},
}) => {
  /**
   * Before changing anything check the repo generator index.js and vanilla.json
   *
   * Icons needs to be denoted as functions. For example:
   * icon: _ => Fluency({ name: 'money', size: '64' })
   * And when used in a component, they need to be called as functions: icon()
   */
  const configObj = {
  name: `Eight Mile`,
  domain: `eightmile.com`,
  slogan: `Trusted IT Services On Your Demand`,
  tel: {
    tel1: `+447254766394`
  },
  email: {
    email1: `ibispams101@gmail.com`
  },
  mobileWidth: 767,
  chat: {
    showWidget: false
  },
  socials: {
    instagram: `webifylake`
  },
  nav: {
    dark: true,
    blur: `1px`,
    logoSrc: `/img/logo/logo.png`,
    logoStyle: {
      height: `40px`
    },
    fadeOnPaths: [
      "/",
      "/contact"
    ]
  },
  mainPics: {
    homePage: {
      imgPath: `/img/page/home.jpg`,
      logo: `/img/logo/logo.png`,
      height: `500px`,
      mobileHeight: `300px`,
      buttonText: `Browse`,
      onButtonClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop),
      inputPlaceHolder: `What are you looking for?`,
      inputOptions: [
{
          label: `DevOps`
        },
{
          label: `Networking`
        },
{
          label: `Web Development`
        },
{
          label: `WiFi Networks`
        },
{
          label: `Network Automation`
        }
      ],
      formGroup: true
    },
    copy1: {
      imgPath: `/img/copy/1.jpg`,
      parallax: true,
      parallaxPace: 0.35,
      noShadow: true,
      imgBottom: `0%`,
      sloganCenter: true,
      slogan: `Technology is best when it brings people together. - Matt Mullenweg`
    },
    contactPage: {
      imgPath: `/img/page/contact.jpg`,
      height: `300px`,
      mobileHeight: `300px`
    }
  },
  headers: {
    infos2: {
      part1: {
        text: `Our Most `
      },
      part2: {
        text: `Popular Categories`,
        gradient: true
      }
    },
    infos3: {
      part1: {
        text: `Your `
      },
      part2: {
        text: `Best Service`,
        gradient: true
      }
    },
    infos4: {
      part1: {
        text: `Shed Light `,
        gradient: true
      },
      part2: {
        text: `To Your Problems`
      }
    },
    infos5: {
      part1: {
        text: `100% `,
        gradient: true
      },
      part2: {
        text: `Satisfied Users`
      }
    },
    album: {
      part1: {
        text: `Our Clients`
      }
    },
    reviews: {
      part1: {
        text: `Reviews`
      }
    },
    services: {
      part1: {
        text: `Services`
      }
    }
  },
  textType: [
    "DevOps",
    "Networking",
    "Web Development"
  ],
  infos: {
    infos1: [
{
        header: `IT Services`,
        body: `Providing DevOps engineering, IP networking, Network Automation`,
        img: `/img/infos/infos1-1.jpg`,
        alt: `infos1-1`,
        icon: _ => IOS17Filled({ name: 'money', size: '50', color: configObj.extras.iconColor1 }),
        onClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop)
      },
{
        header: `Web Design`,
        body: `Creating modern and responsive websites`,
        img: `/img/infos/infos1-2.jpg`,
        alt: `infos1-2`,
        icon: _ => IOS17Filled({ name: 'america', size: '50', color: configObj.extras.iconColor1 }),
        onClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop)
      },
{
        header: `Smart Hands`,
        body: `Offering on-demand technical support`,
        img: `/img/infos/infos1-3.jpg`,
        alt: `infos1-3`,
        icon: _ => IOS17Filled({ name: 'crown', size: '50', color: configObj.extras.iconColor1 }),
        onClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop)
      }
    ],
    infos2: [
{
        header: `DevOps`,
        onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
        icon: _ => IOS17Filled({ name: 'america', size: '50', color: configObj.extras.iconColor2 })
      },
{
        header: `Networking`,
        onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
        icon: _ => IOS17Filled({ name: 'poster', size: '50', color: configObj.extras.iconColor2 })
      },
{
        header: `Web Development`,
        onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
        icon: _ => IOS17Filled({ name: 'edit-chat-history', size: '50', color: configObj.extras.iconColor2 })
      },
{
        header: `WiFi Networks`,
        onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
        icon: _ => IOS17Filled({ name: 'multiple-devices', size: '50', color: configObj.extras.iconColor2 })
      },
{
        header: `Network Automation`,
        onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
        icon: _ => IOS17Filled({ name: 'fund-accounting', size: '50', color: configObj.extras.iconColor2 })
      }
    ],
    infos3: {
      title: `What do we do?`,
      text: `Eight Mile specializes in providing a wide range of IT services including DevOps engineering, IP networking, Network Automation, and Smart Hands. Additionally, they offer professional web design services to create modern and responsive websites.`,
      buttonText: `Check Our Services`,
      img1: `/img/infos/infos2-1.jpg`,
      alt1: `infos2-1`,
      buttonOnClick: navigate => scroll(document.getElementById('services-section').offsetTop),
      flipperTitle: `Tech Solutions Inc.`,
      flipperFlippedTitle: `Tech Solutions Inc.`,
      flipperFlippedText: `Eight Mile provided exceptional IT services that transformed our network infrastructure. Their team was professional and efficient.`
    },
    infos4: {
      header1: {
        part1: {
          text: `About Your `
        },
        part2: {
          text: `Best Service`,
          gradient: true
        }
      },
      header2: {
        part1: {
          text: `How does the process work?`
        }
      },
      text1: `Clients will experience improved efficiency and reliability in their IT infrastructure`,
      text2: `Once a client engages with Eight Mile, they will receive personalized IT solutions tailored to their specific needs.`,
      buttonText: `Explore Options`,
      buttonOnClick: navigate => scroll(document.getElementById('services-section')?.offsetTop),
      infos: [
{
          header: `Efficiency`,
          body: `Enhanced operational efficiency through optimized IT solutions`
        },
{
          header: `Reliability`,
          body: `Increased network reliability and security measures`
        },
{
          header: `Personalized Solutions`,
          body: `Tailored IT services to meet individual business requirements`
        },
{
          header: `Innovation`,
          body: `Implementing cutting-edge technologies for business growth`
        },
{
          header: `Technical Support`,
          body: `24/7 availability for technical assistance and troubleshooting`
        },
{
          header: `Scalability`,
          body: `Flexible solutions that can scale with business expansion`
        }
      ]
    },
    infos5: [
{
        title: `Clients`,
        text: `+`,
        maxValue: 150,
        incrementBy: 150,
        updateInterval: 1,
        color: `rgb(112 144 183)`,
        strokeWidth: 1.5
      },
{
        title: `Projects`,
        text: `+`,
        maxValue: 120,
        incrementBy: 120,
        updateInterval: 1,
        color: `rgb(112 144 183)`,
        strokeWidth: 1.5
      },
{
        title: `Years in Service`,
        text: `+`,
        maxValue: 10,
        incrementBy: 10,
        updateInterval: 1,
        color: `rgb(112 144 183)`,
        strokeWidth: 1.5
      }
    ],
    infos6: [
{
        header: `Contact`,
        body: `Reach out to discuss your IT needs`,
        onClick: navigate => scroll(document.getElementById('services-section')?.offsetTop)
      },
{
        header: `Consultation`,
        body: `Book a consultation for personalized solutions`,
        onClick: navigate => scroll(document.getElementById('services-section')?.offsetTop)
      },
{
        header: `Explore`,
        body: `Discover the range of services offered`,
        onClick: navigate => scroll(document.getElementById('services-section')?.offsetTop)
      }
    ],
    contact: {
      header: `Contact Us`,
      header2: `Have a query for us?`,
      flipperHeader: `Contact Us`,
      flipperHeader2: `Different ways to reach us`,
      flipperImg: `/img/misc/contact.jpg`
    }
  },
  cart: {
    cartName: `Cart`,
    cartSubmitButton: `Contact Us`,
    showPrice: true,
    showQuantity: true,
    showTotal: true,
    cartOnSubmit: async ({ e, inputs, cart, pushEmail }) => {
            e.preventDefault();
    
            try {
              await pushEmail({
                // Mail
                subject: 'New Order',
                to: configObj.email.email1,
                successReply: 'Order submitted to support',
                // HTML
                template: 'adminCart',
                args: { ...inputs, ...cart, currency: configObj.currency.currencySign },
              });
    
              await pushEmail({
                // Mail
                subject: 'Order submitted',
                to: inputs.email,
                successReply: 'Your order was submitted successfully',
                // HTML
                template: 'cart',
                args: { ...inputs, ...cart, currency: configObj.currency.currencySign },
              });
            } catch (e) {
              console.error(e);
            }
          }
  },
  reviews: [
{
      title: `Tech Solutions Inc.`,
      body: `Eight Mile provided exceptional IT services that transformed our network infrastructure. Their team was professional and efficient.`
    },
{
      title: `Digital Innovations Co.`,
      body: `We are extremely satisfied with the web design services offered by Eight Mile. Our new website has significantly improved user engagement.`
    },
{
      title: `Connectivity Solutions Ltd.`,
      body: `The Smart Hands support from Eight Mile has been invaluable to our business operations. Quick response times and expert assistance.`
    }
  ],
  albums: {
    showAlbum: false,
    showInstagram: true,
    folder1: {
      md: 4,
      album: [
        "/img/album1/1.JPG",
        "/img/album1/2.JPG",
        "/img/album1/3.JPG",
        "/img/album1/4.JPG",
        "/img/album1/5.JPG",
        "/img/album1/6.JPG",
        "/img/album1/7.JPG",
        "/img/album1/8.JPG",
        "/img/album1/9.JPG",
        "/img/album1/10.JPG"
      ]
    }
  },
  services: {
    showPrice: true,
    showImage: false,
    multiclick: true,
    style: {
    },
    className: ``,
    serviceGroups: [
{
        serviceGroupName: `IT Services`,
        serviceItems: [
{
            serviceName: `DevOps`,
            id: `DevOps`,
            price: 300,
            minPrice: true,
            imageSrc: ``,
            text: `Implementing efficient development and operations practices`
          },
{
            serviceName: `Networking`,
            id: `Networking`,
            price: 300,
            minPrice: true,
            imageSrc: ``,
            text: `Building secure and reliable network infrastructures`
          },
{
            serviceName: `WiFi Networks`,
            id: `WiFiNetworks`,
            price: 300,
            minPrice: true,
            imageSrc: ``,
            text: `Setting up fast and stable WiFi connections`
          },
{
            serviceName: `Network Automation`,
            id: `NetworkAutomation`,
            price: 300,
            minPrice: true,
            imageSrc: ``,
            text: `Automating network management tasks`
          }
        ]
      },
{
        serviceGroupName: `Web Services`,
        serviceItems: [
{
            serviceName: `Web Development`,
            id: `WebDevelopment`,
            price: 300,
            minPrice: true,
            imageSrc: ``,
            text: `Crafting visually appealing and functional websites`
          }
        ]
      }
    ]
  },
  inputsOptions: {
    sortByOptions: [
{
        value: ``,
        label: `Select`
      },
{
        value: `createdAt:asc`,
        label: `Oldest`
      },
{
        value: `createdAt:desc`,
        label: `Newest`
      },
{
        value: `updatedAt:asc`,
        label: `Updated - oldest`
      },
{
        value: `updatedAt:desc`,
        label: `Updated - soonest`
      }
    ]
  },
  extras: {
    iconColor1: `8aabed`,
    iconColor2: `3374f5`
  }
};

  return configObj;
};
